import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { Inject, PLATFORM_ID } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";


@Injectable({
  providedIn: "root",
})
export class DataSharingService {
  isNew:any;
  private languageSource = new BehaviorSubject<string>('No language');


  public isLoginChanged: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isProfileChanged: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  // public isPermissionChanged: BehaviorSubject<boolean> =
  //   new BehaviorSubject<boolean>(false);
  public isHelpnotification: BehaviorSubject<boolean> =
     new BehaviorSubject<boolean>(false);
     public helpPage: BehaviorSubject<boolean> =
     new BehaviorSubject<boolean>(false);
  public userAvatar: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public searchParam: BehaviorSubject<any> = new BehaviorSubject<any>("");
  public searchEmpty: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  // public notificationHeader: BehaviorSubject<any> = new BehaviorSubject<any>('');
  public notificationHeader: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
  public notificationPage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public notificationData: BehaviorSubject<boolean> = new BehaviorSubject<any>(null);
  public newMessageRecive: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public clearNotification: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showSideBar:BehaviorSubject<boolean>= new BehaviorSubject<boolean>(true);
  public showManageSideBar:BehaviorSubject<boolean>= new BehaviorSubject<boolean>(false);
  
  private storage: any;
  currentLanguage = this.languageSource.asObservable();
  
  constructor(@Inject(PLATFORM_ID) private platformId: any,
    private router: Router, private active: ActivatedRoute) {
    // if (isPlatformBrowser(this.platformId)) {
    this.storage = localStorage;
    // }

   

 
  }
  languageChange(data) {
    this.languageSource.next(data);
    
  }


  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  setItem(key: string, value: string): void {
    this.storage.setItem(key, value);
  }

  removeItem(key: string): void {
    this.storage.removeItem(key);
  }


  clear(): void {
    // this.storage.clear();
    localStorage.removeItem('accessInfo');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('appleData');
    localStorage.removeItem('fbData');

  }

  setUserAvatar(value) {
    this.userAvatar.next(value);
  }

  passNotification(value) {
    // console.log('pass', value)
    this.notificationHeader.next(value);
  }


  searchFaq(query: string) {
    this.searchParam.next(query);
  }
setLocalData(allData){
  // console.log('ak', allData)
  const data ={
    'name': allData.name,
    'email': allData.email,
    'gender': allData.gender,
    'isCompleteProfile': allData.isCompleteProfile,
    'isApproved': allData.isApproved,
    'webFinished': allData.webFinished,
    'saloonRole': allData.saloonRole,
    'businessProfile':allData.businessProfile,
    'profileImage':allData.profileImage,
    'teamSize': allData.teamSize,
    'ServiceProviderSessions':allData.ServiceProviderSessions,
    'notificationEnabled':allData.notificationEnabled,
    'registrationVia':allData.registrationVia,
    }
  localStorage.setItem('language', allData?.language)  
  localStorage.setItem('userInfo', JSON.stringify({ userDetails: data}))

  // this.saveExits.next(data);
}
setBusniess(allData){

  let localDatas = JSON.parse(localStorage.getItem('userInfo') || '');
  localDatas[('userDetails')].businessProfile =  allData.businessProfile;
  localStorage.setItem('userInfo', JSON.stringify(localDatas))

}



  preventBackButton() {
    //   history.pushState(null, '', window.location.href);
    //   window.addEventListener('popstate', function () {
    //   history.pushState(null, '', window.location.href);
    //  });
  }
  redirection(data, type) {
    console.log('......', data)
    sessionStorage.clear()
    if(data)
    {
      if (data?.isCompleteProfile == 0) {
        if (type == 'email') {
          sessionStorage.setItem('language', data.email);
          sessionStorage.setItem('email', data.email);
  
        } else {
          sessionStorage.setItem('phone', data.countryCode + '-' + data.phoneNumber);
        }
        sessionStorage.setItem('dummyAccess', data?.ServiceProviderSessions?.accessToken);
        this.router.navigate(['/verify'])
      } 
      else if (data.isCompleteProfile == 1 && data?.teamSize == 0 && data?.ServiceProviderSessions?.accessToken && data.saloonRole == 1 && data.isApproved == 0) {
        this.router.navigate(['/introduction'])
      } else if (data.isCompleteProfile == 1 && data?.teamSize != 0 && data.businessProfile?.getBusinessProfile == null && data.ServiceProviderSessions?.accessToken && data.saloonRole == 1 && data.isApproved == 0) {
        this.router.navigate(['/profile/business'])
      } else if (data.isCompleteProfile == 1 && data?.teamSize != 0 && data.businessProfile?.getBusinessWorkExp == null && data.ServiceProviderSessions?.accessToken && data.saloonRole == 1 && data.isApproved == 0) {
        this.router.navigate(['/profile/work'])
      }
      else if (data.isCompleteProfile == 1 && data?.teamSize != 0 && data.businessProfile?.getBusinessTravel == null && data.ServiceProviderSessions?.accessToken && data.saloonRole == 1 && data.isApproved == 0) {
        this.router.navigate(['/profile/services'])
      }
      else if (data.isCompleteProfile == 1 && data?.teamSize != 0 && data.businessProfile?.getPortfolio.length == 0 && data.ServiceProviderSessions?.accessToken && data.saloonRole == 1 && data.isApproved == 0) {
        this.router.navigate(['/profile/portfolio'])
      }
      else if (data.isCompleteProfile == 1 && data?.teamSize != 0 && data.businessProfile?.getPortfolio.length != 0 && data.ServiceProviderSessions?.accessToken && data.saloonRole == 1 && (data.isApproved == 0 || data.isApproved == 4)) {
        this.router.navigate(['/profile/congratulations'])
      }else if(data.isCompleteProfile == 1  && data.isApproved ==1 && data.saloonRole == 1 ){
        this.router.navigate(['/accountCheck'])
      }else if(data.isCompleteProfile == 1 && data.isApproved ==2 && data.webFinished == 0 ){
        this.router.navigate(['/finishedAccount'])
      }
      else if(data.isCompleteProfile == 1 && data.isApproved ==2 && data.webFinished == 1 ){
        this.router.navigate(['/dashboard'])
      }
      else if(data.isCompleteProfile == 1 && data.isApproved ==2 && data.webFinished == 1 && data.saloonRole == 2 ){
        this.router.navigate(['/dashboard'])
      }
    }
  }
}
